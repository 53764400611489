<template>
  <div class="data_input">
    <VuePhoneNumberInput
      v-if="type == 'tel'"
      v-model="new_model"
      color="#2981EF"
      error-color="#F5365C"
      @update="phoneFormat"
    />
    <template v-else>
      <input
        v-if="type == 'file'"
        type="file"
        :name="label"
        :class="{
          input_error: error,
          active: set_file
        }"
        @change="forFiles"
      />
      <input
        v-else
        :type="type"
        :name="label"
        :class="{
          input_error: error,
          active: new_model && new_model.length > 0
        }"
        v-model="new_model"
        :maxlength="limit"
        :minlength="min"
        :id="`input-value-${label}`"
        :required="required"
        @input="$emit('newValue', new_model)"
        @keydown.enter="$emit('sendEnterEvent')"
      />
      <label
        :for="`input-value-${label}`"
        :class="{
          'label_place': true,
          'change up left': InputField || type == 'file',
          'text_error': error
        }"
      >{{ label }}</label>
      <span
        v-show="limit"
        :class="{
          'e-hide label_place change up right': true,
          'e-show': new_model && new_model.length > 0
        }"
      >{{ new_model && new_model.length }} / {{ limit }}</span>
      <span
        v-show="min && !limit"
        :class="{
          'e-hide label_place change up right': true,
          'e-show': new_model && new_model.length > 0,
          'text-blue': new_model && new_model.length >= min,
          'text_error': error && new_model && new_model.length < min
        }"
      >Mínimo {{ min }} caracteres</span>
    </template>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import { FadeTransition } from "vue2-transitions";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components: {
    VuePhoneNumberInput,
    FadeTransition
  },
  props: ["label", "type", "model", "error", "limit", "min", "required", "pattern"],

  data() {
    return {
      new_model: this.model,
      set_file: false
    }
  },
  mounted() {
    this.formatPhoneLabels();
  },
  watch: {
    new_model(val) {
      if (this.type == "file") {
        console.log("file-->", val);
      }
      if (this.type == "tel") {
        let item = document.getElementById("add_label_phone");

        if (val && val.length > 0) item.classList.add("change", "up", "left");
        else item.classList.remove("change", "up", "left");
      }
    }
  },
  computed : {
    InputField() {
      if (this.new_model) return this.new_model.length > 0;
    }
  },
  methods: {
    forFiles(event) {
      if (event.target.files && event.target.files.length > 0) {
        this.set_file = true;
        this.$emit('newValue', event);
      }
    },
    formatPhoneLabels() {
      if (this.type == "tel") {
        let code_label = document.getElementsByClassName("country-selector__label");
        let phone_label = document.getElementsByClassName("input-tel__label");
        let phone_input = document.getElementsByClassName("input-tel__input");
        let code_label_2 = `<label class="label_place change up left">Código país</label>`
        let phone_label_2 = `<label class="label_place mb-0" style="top: 40%" id="add_label_phone">Teléfono</label>`
        if (phone_input) phone_input[0].removeAttribute("placeholder");

        code_label[0].outerHTML = code_label_2;
        phone_label[0].outerHTML = phone_label_2;
      }
    },
    phoneFormat(event) {
      if (this.type == 'tel') {
        if (event.formattedNumber) {
          this.new_model = event.formattedNumber.replace("+", "");
          this.$emit("newValue", this.new_model);
        }
      }
    }
  }
}
</script>

<style lang="scss">
.data_input {
  #MazPhoneNumberInput {
    margin-bottom: 1.5rem !important;
    height: 55px !important;

    .label_place {
      font-weight: 400 !important;
      width: fit-content !important;
      position: absolute !important;
      top: 42% !important;
      left: 1rem !important;
      font-size: 1rem !important;
      transition: .25s !important;

      &.change {
        font-size: 12px !important;
        background-color: #fff !important;
        padding: 0.1rem 0.25rem !important;
      }
      &.up {
        top: -.8rem !important;
      }
      &.left {
        left: .8rem !important;
      }
      &.right {
        left: auto;
        right: .8rem !important;
      }
    }
    #MazPhoneNumberInput-23_country_selector, .country-selector__input {
      position: relative !important;
      border-radius: 1rem 0 0 1rem !important;
      padding: .75rem 1rem .75rem 2.75rem !important;
      font-size: 1rem !important;
      color: #181818 !important;
      margin-bottom: 1.5rem !important;
      height: 55px !important;
    }
    #MazPhoneNumberInput-23_phone_number, .input-tel__input {
      position: relative !important;
      font-family: "Manrope", sans-serif !important;
      font-size: 1rem !important;
      color: #767676 !important;
      padding: .75rem 1rem !important;
      border-radius: 0 1rem 1rem 0 !important;
      margin-bottom: 1.5rem !important;
      height: 55px !important;
    }
    .input-tel__input:focus {
      + .label_place {
        font-size: 12px !important;
        color: #767676 !important;
        background-color: #fff !important;
        padding: 0.1rem 0.25rem !important;
        top: -.8rem !important;
        left: .8rem !important;
      }
    }
    .country-selector__country-flag {
      left: 1rem !important;
    }
  }
  .country-selector__list {
    border-radius: 1rem !important;
    box-shadow: 10px 10px 30px #DEEDFF;
  }
  .country-selector__list__item {
    padding: .75rem 1rem;

    &.selected  {
      .dots-text {
        color: #fff;
      }
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill.active,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active {
    + .label_place {
      font-size: 12px !important;
      color: #767676 !important;
      background-color: #fff !important;
      padding: 0.1rem 0.25rem !important;
      top: -.8rem !important;
      left: .8rem !important;
    }
  }
  // input:-internal-autofill-selected {
  //   background-color: #fff !important;
  //   border: 1px solid #2981EF !important;
  //   font-size: 1rem !important;
  // }
}
</style>

<style lang="scss" scoped>
.data_input {
  position: relative;
  width: 100%;
  .label_place {
    font-weight: 400;
    width: fit-content;
    position: absolute;
    top: 22%;
    left: 1rem;
    font-size: 1rem;
    transition: .25s;

    &.change {
      font-size: 12px;
      color: #767676;
      background-color: #fff;
      padding: 0.1rem 0.25rem;
    }
    &.up {
      top: -.8rem;
    }
    &.left {
      left: .8rem;
    }
    &.right {
      left: auto;
      right: .8rem;
    }
  }
  input {
    border-radius: 1rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    height: 55px;
    color: #181818;
    cursor: pointer;
    
    &:hover, &.active { 
      background-color: #fff !important;
      border: 1px solid #2981EF;
    }
    &:focus {
      + .label_place {
        font-size: 12px;
        color: #767676;
        background-color: #fff !important;
        padding: 0.1rem 0.25rem;
        top: -.8rem;
        left: .8rem;
      }
    }
    &::-webkit-file-upload-button {
      height: 100%;
      padding: .25rem .75rem;
      border-radius: .5rem;
      margin-right: .75rem;
    }
  }
  .input_error {
    margin-bottom: 1.5rem !important;
  }
}
</style>