var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data_input"},[(_vm.type == 'tel')?_c('VuePhoneNumberInput',{attrs:{"color":"#2981EF","error-color":"#F5365C"},on:{"update":_vm.phoneFormat},model:{value:(_vm.new_model),callback:function ($$v) {_vm.new_model=$$v},expression:"new_model"}}):[(_vm.type == 'file')?_c('input',{class:{
        input_error: _vm.error,
        active: _vm.set_file
      },attrs:{"type":"file","name":_vm.label},on:{"change":_vm.forFiles}}):((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_model),expression:"new_model"}],class:{
        input_error: _vm.error,
        active: _vm.new_model && _vm.new_model.length > 0
      },attrs:{"name":_vm.label,"maxlength":_vm.limit,"minlength":_vm.min,"id":`input-value-${_vm.label}`,"required":_vm.required,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.new_model)?_vm._i(_vm.new_model,null)>-1:(_vm.new_model)},on:{"input":function($event){return _vm.$emit('newValue', _vm.new_model)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('sendEnterEvent')},"change":function($event){var $$a=_vm.new_model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.new_model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.new_model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.new_model=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_model),expression:"new_model"}],class:{
        input_error: _vm.error,
        active: _vm.new_model && _vm.new_model.length > 0
      },attrs:{"name":_vm.label,"maxlength":_vm.limit,"minlength":_vm.min,"id":`input-value-${_vm.label}`,"required":_vm.required,"type":"radio"},domProps:{"checked":_vm._q(_vm.new_model,null)},on:{"input":function($event){return _vm.$emit('newValue', _vm.new_model)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('sendEnterEvent')},"change":function($event){_vm.new_model=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.new_model),expression:"new_model"}],class:{
        input_error: _vm.error,
        active: _vm.new_model && _vm.new_model.length > 0
      },attrs:{"name":_vm.label,"maxlength":_vm.limit,"minlength":_vm.min,"id":`input-value-${_vm.label}`,"required":_vm.required,"type":_vm.type},domProps:{"value":(_vm.new_model)},on:{"input":[function($event){if($event.target.composing)return;_vm.new_model=$event.target.value},function($event){return _vm.$emit('newValue', _vm.new_model)}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('sendEnterEvent')}}}),_c('label',{class:{
        'label_place': true,
        'change up left': _vm.InputField || _vm.type == 'file',
        'text_error': _vm.error
      },attrs:{"for":`input-value-${_vm.label}`}},[_vm._v(_vm._s(_vm.label))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.limit),expression:"limit"}],class:{
        'e-hide label_place change up right': true,
        'e-show': _vm.new_model && _vm.new_model.length > 0
      }},[_vm._v(_vm._s(_vm.new_model && _vm.new_model.length)+" / "+_vm._s(_vm.limit))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.min && !_vm.limit),expression:"min && !limit"}],class:{
        'e-hide label_place change up right': true,
        'e-show': _vm.new_model && _vm.new_model.length > 0,
        'text-blue': _vm.new_model && _vm.new_model.length >= _vm.min,
        'text_error': _vm.error && _vm.new_model && _vm.new_model.length < _vm.min
      }},[_vm._v("Mínimo "+_vm._s(_vm.min)+" caracteres")])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }